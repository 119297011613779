import { AxiosError, AxiosResponse } from 'axios';
import axiosApi from './config/axios';
import { getError } from './config/getError';
import { User } from '../../entities/User';
import { Room, RoomStatus } from '../../entities/Room';

type LoginPayload = {
  provider: 'email';
  payload: {
    email: string;
    password: string;
  };
};

type LoginResponse =
  | {
      success: true;
      access_token: string;
      refresh_token: string;
    }
  | {
      success: false;
      error: string;
    };

async function login(payload: LoginPayload) {
  try {
    const response = await axiosApi.post<LoginPayload, AxiosResponse<LoginResponse>>(
      '/admin/login',
      payload,
    );

    if (!response.data.success) {
      throw new Error(getError(response.data.error));
    }

    return response.data;
  } catch (error) {
    throw new Error(getError(error));
  }
}

async function me() {
  try {
    const response = await axiosApi.get<User>('/auth/me');
    return response.data;
  } catch (e) {
    const error = e as AxiosError;
    console.log(error);
  }
}

async function getRoomDetails(id: string) {
  try {
    const response = await axiosApi.get<Room>(`/rooms/${id}`);
    return response.data;
  } catch (e) {
    throw new Error(getError(e));
  }
}

type RoomQueryParams = Partial<{
  offset: number;
  limit: number;
  status: RoomStatus;
  topics: string;
  sort: string;
}>;

export type GetRoomsResponse = {
  rooms: Room[];
  totalPages: number;
};

async function getForYouRooms(params?: RoomQueryParams) {
  try {
    const response = await axiosApi.get<GetRoomsResponse>('/discover/rooms/forYou', {
      params,
    });

    return response.data;
  } catch (e) {
    throw new Error(getError(e));
  }
}

const authApi = {
  login,
  me,
};

const roomsApi = {
  getRoomDetails,
};

const discoverApi = {
  getForYouRooms,
};

export const api = {
  auth: authApi,
  discover: discoverApi,
  rooms: roomsApi,
};
