import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { deleteCookie } from 'cookies-next';
import { api } from '../services/api';

const Home = () => {
  const router = useRouter();

  useEffect(() => {
    const validityCheck = async () => {
      const user = await api.auth.me();
      if (!user?.pro_subscribed) {
        deleteCookie('access_token');
        deleteCookie('refresh_token');
        router.push('/login');
        return;
      }

      router.push('/dashboard');
    };
    validityCheck();
  }, []);

  return <></>;
};

export default Home;
